define("discourse/plugins/discourse-coupa-people/discourse/controllers/people", ["exports", "@ember/controller", "@ember/object", "discourse/lib/debounce", "discourse/lib/decorators"], function (_exports, _controller, _object, _debounce, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MIN_SEARCH_LENGTH = 3,
    PAGE_SIZE = 24;
  class PeopleController extends _controller.default {
    loading = false;
    canLoadNext = false;
    canLoadPrev = false;
    page = 0;
    roles = null;
    regions = null;
    industries = null;
    commodities = null;
    searchTerm = null;
    search() {
      if (this.searchTerm.length < MIN_SEARCH_LENGTH && this.searchTerm.length > 0) {
        return;
      }
      this.set("page", 0);
      this.set("term", this.searchTerm);
    }
    updateProps() {
      const canLoadMore = this.get("model.people").length === PAGE_SIZE;
      this.setProperties({
        canLoadNext: canLoadMore,
        roles: this.prepOptions(this.model.extras.roles),
        regions: this.prepOptions(this.model.extras.regions),
        industries: this.prepOptions(this.model.extras.industries),
        commodities: this.prepOptions(this.model.extras.commodities),
        searchTerm: this.term,
        canLoadPrev: this.page > 0
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "updateProps", [(0, _decorators.observes)("model")]))();
    prepOptions(values) {
      return values.map(value => {
        return {
          value,
          name: value
        };
      });
    }
    searchTermLongEnough(term) {
      return term && term.length >= MIN_SEARCH_LENGTH;
    }
    static #_2 = (() => dt7948.n(this.prototype, "searchTermLongEnough", [(0, _decorators.default)("term")]))();
    loadNext() {
      this.incrementProperty("page");
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadNext", [_object.action]))();
    loadPrev() {
      this.decrementProperty("page");
    }
    static #_4 = (() => dt7948.n(this.prototype, "loadPrev", [_object.action]))();
    dropdownChanged(type, value) {
      this.set("page", 0);
      this.set(type, value);
    }
    static #_5 = (() => dt7948.n(this.prototype, "dropdownChanged", [_object.action]))();
    searchTermChanged() {
      (0, _debounce.default)(this, this.search, 500);
    }
    static #_6 = (() => dt7948.n(this.prototype, "searchTermChanged", [_object.action]))();
    clearSearch() {
      this.set("page", 0);
      this.set("term", null);
    }
    static #_7 = (() => dt7948.n(this.prototype, "clearSearch", [_object.action]))();
  }
  _exports.default = PeopleController;
});
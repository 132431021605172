define("discourse/plugins/discourse-coupa-people/discourse/routes/people", ["exports", "@ember/routing/route", "discourse/lib/ajax"], function (_exports, _route, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PeopleRoute extends _route.default {
    queryParams = {
      page: {
        refreshModel: true
      },
      role: {
        refreshModel: true
      },
      region: {
        refreshModel: true
      },
      industry: {
        refreshModel: true
      },
      commodity: {
        refreshModel: true
      },
      term: {
        refreshModel: true
      }
    };
    model(params) {
      return (0, _ajax.ajax)("/people.json", {
        data: params
      });
    }
  }
  _exports.default = PeopleRoute;
});